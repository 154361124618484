import styled from 'styled-components';
import React from 'react';
import { SamePositionStonePair } from '../../types/SamePositionStonePair';
import { StoneNode } from '../goban/StoneNode';
import { convertStonePointTypeToImageSource } from '../utils/goban';

type Props = { samePositionStonePairs: SamePositionStonePair[] };

const SamePositionStonePairs: React.FC<Props> = ({
  samePositionStonePairs,
}) => {
  const pairs = samePositionStonePairs.map((samePositionStonePair, i) => {
    const firstPointProps = {
      imgSource: convertStonePointTypeToImageSource(
        samePositionStonePair.firstStone.type,
      ),
      text: samePositionStonePair.firstStone?.text ?? '',
    };
    const firstStoneNode = (
      <StoneNode
        key={`first_${i}`}
        size={24}
        point={firstPointProps}
      ></StoneNode>
    );
    const laterStoneNodes = samePositionStonePair.laterStones.map(
      (laterStonePoint, k) => {
        const laterPointProps = {
          imgSource: convertStonePointTypeToImageSource(laterStonePoint.type),
          text: laterStonePoint?.text ?? '',
        };
        return (
          <StoneNode
            key={`later_${i}_${k}`}
            size={24}
            point={laterPointProps}
          ></StoneNode>
        );
      },
    );

    return (
      <PareContainer key={i}>
        {laterStoneNodes}【{firstStoneNode}】
      </PareContainer>
    );
  });

  return <div>{pairs}</div>;
};

const PareContainer = styled.div`
  display flex;
`;

export default SamePositionStonePairs;
