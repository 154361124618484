import { Footer } from 'antd/es/layout/layout';

const FooterContent = () => {
  return (
    <Footer>
      囲碁の株式会社パンダネット Copyright© PANDANET Inc. All right reserved.
    </Footer>
  );
};

export default FooterContent;
