import { useRecoilState } from 'recoil';
import { TablePaginationConfig } from 'antd';
import { explainTablePaginationState } from '../states/explainTablePaginationState';

export const useTablePaginationConfig = (): TablePaginationConfig => {
  const [current, setCurrent] = useRecoilState(explainTablePaginationState);

  const handleChangePage = (newPage: number) => {
    setCurrent(newPage);
  };

  return {
    current,
    onChange: handleChangePage,
  };
};
