import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { QuestionExplain } from '../../types/QuestionExplain';
import Goban from '../goban/Goban';
import Hint from '../common/Hint';
import { GobanProps } from '../../types/GobanProps';
import Accordions from './Accordions';
import StringBox from '../common/StringBox';

type Props = {
  questionExplain: QuestionExplain;
  gobanProps: GobanProps;
};

const ExplainContent: React.FC<Props> = ({ questionExplain, gobanProps }) => {
  const accordionsProps = {
    selectedOption: questionExplain.selectedOption,
    area: questionExplain.question.header.area,
    explains: questionExplain.explains,
  };
  return (
    <StyledCard
      title={
        <Title>
          <LinkToExplains to="/explains">解説一覧に戻る</LinkToExplains>
          <p>{questionExplain?.question.header.title}</p>
        </Title>
      }
    >
      <StyledRow gutter={16}>
        <Col span={12}>
          {questionExplain.answerOption && (
            <StringBoxWrapper>
              <StringBox string={`正解 : ${questionExplain.answerOption}`} />
              <StringBox string={`回答 : ${questionExplain.selectedOption}`} />
            </StringBoxWrapper>
          )}
          <HintWrapper>
            <Hint hint={questionExplain?.problem.hint ?? ''} />
          </HintWrapper>
        </Col>
        <Col span={12}>{gobanProps && <Goban {...gobanProps} />}</Col>
      </StyledRow>
      <Accordions {...accordionsProps} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 80%;
  min-width: 500px;
`;

const StyledRow = styled(Row)`
  height: 400px;
`;

const StringBoxWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  gap: 30px;
`;

const HintWrapper = styled.div`
  padding: 12px;
  margin-top: 3vh;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
`;

const Title = styled.div`
  margin-top: 10px;
`;

const LinkToExplains = styled(Link)`
  font-weight: normal;
  font-size: 18px;
`;

export default ExplainContent;
