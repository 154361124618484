import React from 'react';
import { Card, Col, Row } from 'antd';
import styled from 'styled-components';
import { Question } from '../../types/Question';
import Goban from '../goban/Goban';
import { GobanProps } from '../../types/GobanProps';
import Hint from '../common/Hint';
import StringBox from '../common/StringBox';
import CountDownProgressBar, { ProgressBarProps } from './CountDownProgressBar';
import NextButton, { NextButtonProps } from './NextButton';
import RadioButtons, { RadioButtonsProps } from './RadioButtons';

type Props = {
  question?: Question;
  gobanProps?: GobanProps;
  radioButtonsProps: RadioButtonsProps;
  nextButtonProps: NextButtonProps;
  progressBarProps: ProgressBarProps;
};

const TimeAttackContent: React.FC<Props> = ({
  question,
  gobanProps,
  radioButtonsProps,
  nextButtonProps,
  progressBarProps,
}) => {
  return (
    <>
      <StyledCard title={question?.header.title}>
        <Row gutter={16}>
          <Col span={12}>
            {question?.header.turn && (
              <StringBoxWrapper>
                <StringBox
                  string={question.header.turn === 'B' ? '黒番' : '白番'}
                />
              </StringBoxWrapper>
            )}
            <HintWrapper>
              <Hint hint={question?.problem.hint || ''} />
            </HintWrapper>
            <RadioButtonsWrapper>
              <RadioButtons {...radioButtonsProps} />
            </RadioButtonsWrapper>
            <NextButtonWrapper>
              <NextButton {...nextButtonProps} />
            </NextButtonWrapper>
            <ProgressBarWrapper>
              <CountDownProgressBar {...progressBarProps} />
            </ProgressBarWrapper>
          </Col>
          <Col span={12}>{gobanProps && <Goban {...gobanProps} />}</Col>
        </Row>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)`
  width: 80%;
  height: 73vh;
  min-width: 500px;
  min-height: 550px;
`;

const StringBoxWrapper = styled.div`
  margin-top: 5px;
`;

const HintWrapper = styled.div`
  height: 25vh;
  padding: 12px;
  margin-top: 3vh;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  overflow-y: auto;
`;

const RadioButtonsWrapper = styled.div`
  margin-top: 3vh;
`;

const NextButtonWrapper = styled.div`
  margin-top: 3vh;
`;

const ProgressBarWrapper = styled.div`
  margin-top: 4vh;
`;

export default TimeAttackContent;
