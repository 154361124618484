import { atomFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'visitedLinkStateStorage',
  storage: localStorage,
});

export const visitedLinkState = atomFamily<boolean, string>({
  key: 'visitedLinkState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const removeUserAnswerState = () => {
  localStorage.removeItem('visitedLinkStateStorage');
};
