// 総合スコアに対応したラベルを返す
export const toTotalScoreLabel = (totalScore: number): string => {
  let totalScoreLabel = '-';
  if (totalScore <= 2) {
    totalScoreLabel = 'D';
  } else if (totalScore <= 4) {
    totalScoreLabel = 'C';
  } else if (totalScore <= 6) {
    totalScoreLabel = 'B';
  } else if (totalScore <= 8) {
    totalScoreLabel = 'A';
  } else if (totalScore == 9) {
    totalScoreLabel = 'S';
  } else if (totalScore == 10) {
    totalScoreLabel = 'SS';
  }
  return totalScoreLabel;
};
