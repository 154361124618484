// 問題集の各問題の正当数の合計を正答率成績に変換する
export const convertAccuracyToScore = (
  correctNumber: number,
  questionNumber: number,
): number => {
  const remainingAccuracyPercent = Math.floor(
    (correctNumber / questionNumber) * 100,
  );

  let score = 0;
  if (remainingAccuracyPercent <= 39) {
    score = 1;
  } else if (remainingAccuracyPercent <= 59) {
    score = 2;
  } else if (remainingAccuracyPercent <= 79) {
    score = 3;
  } else if (remainingAccuracyPercent <= 99) {
    score = 4;
  } else if (remainingAccuracyPercent == 100) {
    score = 5;
  }
  return score;
};
