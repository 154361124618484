import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { loginState } from '../../states/loginState';

type Props = {
  component: ReactNode;
};

const RequireLogin: React.FC<Props> = (props: Props) => {
  const isLogin = useRecoilValue(loginState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate('/login');
    }
  }, [isLogin]);

  return <>{isLogin && props.component}</>;
};

export default RequireLogin;
