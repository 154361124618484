import axios from 'axios';
import { useEffect, useState } from 'react';
import { QuestionGroup, QuestionGroupsMap } from '../types/QuestionGroup';
import { toScoreLabel } from '../components/utils/scoreConverter/toScoreLabel';
import { toTotalScoreLabel } from '../components/utils/scoreConverter/toTotalScoreLabel';
import { Question } from '../types/Question';

// 難易度ごとの問題集リスト、ローディング判定値、エラー判定値を返す
const useQuestionGroups = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [questionGroupsMap, setQuestionGroupsMap] =
    useState<QuestionGroupsMap>();

  useEffect(() => {
    try {
      const fetchQuestionGroups = async () => {
        setLoading(true);
        const [
          beginnerQuestionGroups,
          easyQuestionGroups,
          normalQuestionGroups,
          hardQuestionGroups,
        ] = await Promise.all([
          getQuestionGroups('beginner'),
          getQuestionGroups('easy'),
          getQuestionGroups('normal'),
          getQuestionGroups('hard'),
        ]);
        setQuestionGroupsMap({
          beginner: beginnerQuestionGroups,
          easy: easyQuestionGroups,
          normal: normalQuestionGroups,
          hard: hardQuestionGroups,
        });
        setLoading(false);
      };
      fetchQuestionGroups();
    } catch (error) {
      console.error('Error fetching QuestionGroups:', error);
      setError(true);
    }
  }, []);

  const getQuestionGroups = async (
    difficulty: string,
  ): Promise<QuestionGroup[]> => {
    const { data } = await axios(
      `${process.env.REACT_APP_PUBLIC_API_URL}/get_question_group`,
      {
        params: {
          difficulty,
        },
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        withCredentials: true,
      },
    );

    const questionGroups: QuestionGroup[] = data
      .filter((questionGroup: QuestionGroup) => {
        return !isFutureDate(questionGroup.publishedDate);
      })
      .map((questionGroup: QuestionGroup, index: number) => {
        const displayNumber = parseInt(questionGroup.displayQuestionNumber);
        const shuffledQuestions = shuffleOrder(questionGroup.questions);

        const questions =
          displayNumber > 0
            ? shuffledQuestions.slice(0, displayNumber)
            : shuffledQuestions;

        const bestScoreLabel = {
          time: '-',
          accuracy: '-',
          total: '-',
        };

        if (questionGroup.bestScore) {
          bestScoreLabel.time = toScoreLabel(questionGroup.bestScore.time);
          bestScoreLabel.accuracy = toScoreLabel(
            questionGroup.bestScore.accuracy,
          );
          bestScoreLabel.total = toTotalScoreLabel(
            questionGroup.bestScore.total,
          );
        }

        return { ...questionGroup, key: index, questions, bestScoreLabel };
      });
    return questionGroups;
  };

  return { questionGroupsMap, error, loading };
};

const isFutureDate = (date: string): boolean => {
  const dateObj = new Date(
    date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8),
  );

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  return dateObj >= tomorrow;
};

const shuffleOrder = (questions: Question[]): Question[] => {
  const array = [...questions];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export default useQuestionGroups;
