import { Button, Card, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QuestionGroup, QuestionGroupsMap } from '../../types/QuestionGroup';
import { useSetRecoilState } from 'recoil';
import { questionGroupState } from '../../states/questionGroupState';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  activeTabKey: string;
  onTabChange: (key: string) => void;
  questionGroupsMap?: QuestionGroupsMap;
  loading: boolean;
};

const QuestionGroupsTableSP: React.FC<Props> = ({
  activeTabKey,
  onTabChange,
  questionGroupsMap,
  loading,
}) => {
  const setQuestionGroup = useSetRecoilState(questionGroupState);
  const navigate = useNavigate();

  const columns: ColumnsType<QuestionGroup> = [
    {
      key: 'key',
      render: (_, record) => (
        <FlexBox>
          <Row>
            <Head></Head>
            <Value>{record.name}</Value>
          </Row>
          <Row>
            <Head>時間成績：</Head>
            <Value>{record.bestScoreLabel?.time}</Value>
          </Row>
          <Row>
            <Head>正解率：</Head>
            <Value>{record?.bestScoreLabel?.accuracy}</Value>
          </Row>
          <Row>
            <Head>総合成績：</Head>
            <Value>{record?.bestScoreLabel?.total}</Value>
          </Row>
          <Button
            onClick={() => {
              setQuestionGroup(record);
              navigate('/time-attack');
            }}
          >
            問題を解く
          </Button>
        </FlexBox>
      ),
    },
  ];

  const exerciseCorses: Record<string, React.ReactNode> = {
    beginner: (
      <Table
        columns={columns}
        dataSource={questionGroupsMap?.beginner}
        loading={loading}
      />
    ),
    easy: (
      <Table
        columns={columns}
        showHeader={false}
        dataSource={questionGroupsMap?.easy}
        loading={loading}
      />
    ),
    normal: (
      <Table
        columns={columns}
        showHeader={false}
        dataSource={questionGroupsMap?.normal}
        loading={loading}
      />
    ),
    hard: (
      <Table
        columns={columns}
        showHeader={false}
        dataSource={questionGroupsMap?.hard}
        loading={loading}
      />
    ),
  };

  interface Level {
    key: string;
    tab: string;
  }

  const level: Level[] = [
    {
      key: 'beginner',
      tab: '入門',
    },
    {
      key: 'easy',
      tab: '初級',
    },
    {
      key: 'normal',
      tab: '中級',
    },
    {
      key: 'hard',
      tab: '上級',
    },
  ];

  return (
    <StyledCard
      tabList={level}
      activeTabKey={activeTabKey}
      onTabChange={(key) => onTabChange(key)}
    >
      {exerciseCorses[activeTabKey]}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 90vw;
  min-width: 350px;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Row = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  margin-bottom: 15px;
`;

const Head = styled.div`
  flex-basis: 40%;
`;

const Value = styled.div`
  flex-basis: 60%;
`;

export default QuestionGroupsTableSP;
