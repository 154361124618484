// 問題集の各問題の制限時間の合計と回答時間の合計を時間成績に変換する
export const convertTimeToScore = (
  spentTime: number,
  totalTime: number,
): number => {
  const spentTimePercent = Math.floor((spentTime / totalTime) * 100);
  let score;
  if (spentTimePercent <= 65) {
    score = 5;
  } else if (spentTimePercent <= 77) {
    score = 4;
  } else if (spentTimePercent <= 87) {
    score = 3;
  } else if (spentTimePercent <= 95) {
    score = 2;
  } else {
    score = 1;
  }
  return score;
};
