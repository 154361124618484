import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { UserAnswer } from '../types/UserAnswer';

const { persistAtom } = recoilPersist({
  key: 'userAnswerStateStorage',
  storage: localStorage,
});

const defaultUserAnswer = {
  questionGroupId: '',
  individualAnswers: [],
};

export const userAnswerState = atom<UserAnswer>({
  key: 'userAnswerState',
  default: defaultUserAnswer,
  effects_UNSTABLE: [persistAtom],
});

export const removeUserAnswerState = () => {
  localStorage.removeItem('userAnswerStateStorage');
};
