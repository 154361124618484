import { Layout } from 'antd';
import HeaderTitleLogoImage from '../../resources/header_logo.png';
const { Header } = Layout;

const TitleBar = () => {
  return (
    <Header
      style={{
        height: '80px',
        backgroundImage: `url(${HeaderTitleLogoImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#ff7400',
      }}
    ></Header>
  );
};
export default TitleBar;
