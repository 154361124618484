import { useMemo } from 'react';
import { QuestionGroup } from '../../types/QuestionGroup';

const useSortedAnswer = (
  questionGroup: QuestionGroup,
  questionIndex: number,
) => {
  const questionSortedAnswer = useMemo(() => {
    return {
      ...questionGroup.questions[questionIndex],
      answers: questionGroup.questions[questionIndex].answers
        .filter((answer) => answer.option !== '')
        .sort((answer, nextAnswer) =>
          answer.option.localeCompare(nextAnswer.option),
        ),
    };
  }, [questionGroup, questionIndex]);

  return questionSortedAnswer;
};

export default useSortedAnswer;
