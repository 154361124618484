import { BoardArea } from '../../types/BoardArea';
import { GobanLayoutSize } from '../../types/GobanLayoutSize';
import { GobanProps } from '../../types/GobanProps';
import { Point } from '../../types/Point';

export const GOBAN_MAX_RANGE = 19;

export const initializeBoard = (gobanRange = GOBAN_MAX_RANGE): Point[][] => {
  const gobanEdgeIndex = gobanRange - 1;
  return Array.from({ length: gobanRange }, (_, x) =>
    Array.from({ length: gobanRange }, (_, y) => {
      let imgSource = 'center';
      if (x === 0 && y === 0) {
        imgSource = 'top_left';
      } else if (x === gobanEdgeIndex && y === 0) {
        imgSource = 'top_right';
      } else if (x === 0 && y === gobanEdgeIndex) {
        imgSource = 'bottom_left';
      } else if (x === gobanEdgeIndex && y === gobanEdgeIndex) {
        imgSource = 'bottom_right';
      } else if (y === 0) {
        imgSource = 'top';
      } else if (y === gobanEdgeIndex) {
        imgSource = 'bottom';
      } else if (x === 0) {
        imgSource = 'left';
      } else if (x === gobanEdgeIndex) {
        imgSource = 'right';
      }
      return {
        imgSource,
        text: '',
      };
    }),
  );
};

export const convertStonePointTypeToImageSource = (
  pointType: 'B' | 'W' | 'C' | 'N',
): string => {
  switch (pointType) {
    case 'B':
      return 'blackstone';
    case 'W':
      return 'whitestone';
    case 'C':
      return 'candidate';
    case 'N':
      return 'center';
  }
};

export const calculateStoneSize = (
  gobanLayoutSize: GobanLayoutSize,
  boardArea: BoardArea,
) => {
  const stoneSize =
    Math.min(gobanLayoutSize.height, gobanLayoutSize.width) /
    Math.max(boardArea.xRange, boardArea.yRange);
  return Math.min(stoneSize, 50);
};

export const isOutOfArea = (
  area: GobanProps['area'],
  x: number,
  y: number,
): boolean => {
  return !(
    x >= area.left - 1 &&
    x <= area.right - 1 &&
    y >= area.top - 1 &&
    y <= area.bottom - 1
  );
};

export const makeImagePath = (imageSource: string): string => {
  return `./${imageSource}.png`;
};

export const makeTextColor = (imageSource: string): string => {
  return (
    {
      blackstone: 'white',
      whitestone: 'black',
      candidate: 'blue',
    }[imageSource] ?? 'black'
  );
};
