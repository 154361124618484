import { ConfigProvider } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Explain from './pages/Explain';
import Explains from './pages/Explains';
import Login from './pages/Login';
import TimeAttack from './pages/TimeAttack';
import QuestionGroups from './pages/QuestionGroups';
import { RecoilRoot } from 'recoil';
import RequireLogin from './components/auth/RequireLogin';
import 'normalize.css';
import { MediaQueryProvider } from './components/provider/MediaQueryProvider';
import { Layout } from './components/layout/Layout';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <MediaQueryProvider>
          <Layout>
            <ConfigProvider theme={{ token: { fontSize: 18 } }}>
              <Content>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    index
                    element={<RequireLogin component={<QuestionGroups />} />}
                  />
                  <Route
                    path="/time-attack"
                    element={<RequireLogin component={<TimeAttack />} />}
                  />
                  <Route
                    path="/explain"
                    element={<RequireLogin component={<Explain />} />}
                  />
                  <Route
                    path="/explains"
                    element={<RequireLogin component={<Explains />} />}
                  />
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </Content>
            </ConfigProvider>
          </Layout>
        </MediaQueryProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
};

export default App;
