import { RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Question } from '../types/Question';
import { useRecoilState } from 'recoil';
import { questionGroupState } from '../states/questionGroupState';
import {
  userAnswerState,
  removeUserAnswerState,
} from '../states/userAnswerState';
import axios from 'axios';
import { GobanProps } from '../types/GobanProps';
import useFetchCsrfToken from '../hooks/useFetchCsrfToken';
import useSortedAnswer from '../hooks/timeAttack/useQuestionSetup';
import { useMediaQueryContext } from '../components/provider/MediaQueryProvider';
import TimeAttackContent from '../components/timeAttack/TimeAttackContent';
import TimeAttackContentSP from '../components/timeAttack/TimeAttackContentSP';
import { UserAnswer } from '../types/UserAnswer';
import { QuestionGroup } from '../types/QuestionGroup';
import { convertTimeToScore } from '../components/utils/scoreConverter/convertTimeToScore';
import { convertAccuracyToScore } from '../components/utils/scoreConverter/convertAccuracyToScore';

const TimeAttack: React.FC = () => {
  removeUserAnswerState();
  const { isSpSite } = useMediaQueryContext();
  const [questionGroup] = useRecoilState(questionGroupState);
  const [userAnswer, setUserAnswer] = useRecoilState(userAnswerState);
  const [question, setQuestion] = useState<Question>();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState('A');
  const [timeLimit, setTimeLimit] = useState<number>(questionGroup.timeLimit);
  const [gobanProps, setGobanProps] = useState<GobanProps>();
  const navigate = useNavigate();
  const csrfToken = useFetchCsrfToken();
  const questionSortedAnswer = useSortedAnswer(questionGroup, questionIndex);

  useEffect(() => {
    setGobanProps({
      stonePoints: questionSortedAnswer.problem.stonePoints,
      area: questionSortedAnswer.header.area,
    });
    setTimeLimit(questionGroup.timeLimit);
    setQuestion(questionSortedAnswer);
    setSelectedAnswer(questionSortedAnswer.answers[0].option);
    setIsLastQuestion(questionIndex >= questionGroup.questions.length - 1);
  }, [questionIndex]);

  useEffect(() => {
    const isReloaded = localStorage.getItem('isReloaded');
    if (isReloaded) {
      navigate('/');
    } else {
      localStorage.setItem('isReloaded', 'true');
    }

    return () => {
      localStorage.removeItem('isReloaded');
    };
  }, []);

  const handleNextButton = () => {
    const newUserAnswer = makeNewUserAnswer();
    setUserAnswer(newUserAnswer);
    setQuestionIndex(questionIndex + 1);
  };

  const handleResultButton = async () => {
    const newUserAnswer = makeNewUserAnswer();
    const score = calculateScore(questionGroup, newUserAnswer);
    setUserAnswer({ ...newUserAnswer, score });
    const now = new Date();
    const dateTime = now.toISOString().replace(/\D/g, '').slice(0, 14);

    await axios.post(
      `${process.env.REACT_APP_PUBLIC_API_URL}/send_score`,
      {
        ...newUserAnswer,
        dateTime,
        score,
      },
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'csrf-token': csrfToken,
        },
        withCredentials: true,
      },
    );
    navigate('/explains');
  };

  const makeNewUserAnswer = () => {
    const singleProblemAnswer = timeLimit === 0 ? 'T' : selectedAnswer;
    return {
      questionGroupId: questionGroup.questionGroupId,
      individualAnswers: [
        ...userAnswer.individualAnswers,
        {
          questionNo: question?.questionNo || '',
          time: questionGroup.timeLimit - timeLimit,
          selected: singleProblemAnswer,
          correct: singleProblemAnswer === question?.header.answer,
        },
      ],
    };
  };

  const calculateScore = (
    questionGroup: QuestionGroup,
    userAnswer: UserAnswer,
  ) => {
    let totalTimeSpent = 0;
    let totalCorrectNumber = 0;
    userAnswer.individualAnswers.forEach((answer) => {
      totalTimeSpent += answer.time;
      if (answer.correct) {
        totalCorrectNumber++;
      }
    });

    const timeScore = convertTimeToScore(
      totalTimeSpent,
      questionGroup.timeLimit * questionGroup.questions.length,
    );

    const accuracyScore = convertAccuracyToScore(
      totalCorrectNumber,
      questionGroup.questions.length,
    );

    return {
      time: timeScore,
      accuracy: accuracyScore,
      total: timeScore + accuracyScore,
    };
  };

  const radioButtonsProps = {
    question,
    selectedAnswer,
    handleChange: (e: RadioChangeEvent) => setSelectedAnswer(e.target.value),
  };

  const nextButtonProps = {
    isLastQuestion,
    handleNextButton,
    handleResultButton,
  };

  const progressBarProps = {
    isLastQuestion,
    initialTimeLimit: questionGroup.timeLimit,
    timeLimit,
    setTimeLimit,
    handleNextButton,
    handleResultButton,
  };

  const timeAttackContentProps = {
    question,
    gobanProps,
    radioButtonsProps,
    nextButtonProps,
    progressBarProps,
  };

  return (
    <Container>
      {isSpSite ? (
        <TimeAttackContentSP {...timeAttackContentProps} />
      ) : (
        <TimeAttackContent {...timeAttackContentProps} />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 3% auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default TimeAttack;
