import { FC } from 'react';
import { Layout as LayoutAntd } from 'antd';
import styled from 'styled-components';
import TitleBar from '../header/TitleBar';
import FooterContent from '../footer/FooterContent';

type Props = {
  children: React.ReactNode;
};

export const Layout: FC<Props> = ({ children }: Props) => {
  return (
    <StyledLayout>
      <TitleBar />
      {children}
      <FooterContent />
    </StyledLayout>
  );
};

const StyledLayout = styled(LayoutAntd)`
  height: 100%;
  width: 100vw;
  min-height: 100vh;
`;
