import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import styled from 'styled-components';
import { Question } from '../../types/Question';

export type RadioButtonsProps = {
  question?: Question;
  selectedAnswer: string;
  handleChange: (e: RadioChangeEvent) => void;
};

const RadioButtons: React.FC<RadioButtonsProps> = ({
  question,
  selectedAnswer,
  handleChange,
}) => {
  return (
    <StyledRadioGroup onChange={handleChange} value={selectedAnswer}>
      {question?.answers.map((answer, index) => {
        return (
          <StyledRadio key={index} value={answer.option}>
            {answer.option}
          </StyledRadio>
        );
      })}
    </StyledRadioGroup>
  );
};

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledRadio = styled(Radio)`
  font-size: 20px;
  padding: 15px;
`;

export default RadioButtons;
