import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/login/LoginForm';
import useLogin from '../hooks/useLogin';
import { useMediaQueryContext } from '../components/provider/MediaQueryProvider';
import LoginFormSP from '../components/login/LoginFormSP';
import styled from 'styled-components';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { isSpSite } = useMediaQueryContext();
  const { login, loading, errorMessage } = useLogin();
  const handleSubmit = async (values: Record<string, any>) => {
    const result = await login(values);
    if (result) {
      navigate('/');
    }
  };
  const loginFormProps = {
    loading,
    errorMessage,
    onFinish: handleSubmit,
  };

  return (
    <Container>
      {isSpSite ? (
        <LoginFormSP {...loginFormProps}></LoginFormSP>
      ) : (
        <LoginForm {...loginFormProps}></LoginForm>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 5% auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Login;
