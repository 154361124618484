import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import styled from 'styled-components';

type Props = {
  loading: boolean;
  errorMessage: string;
  onFinish: (values: Record<string, any>) => void;
};

const LoginForm: React.FC<Props> = ({ loading, errorMessage, onFinish }) => {
  return (
    <StyledCard title="ログインフォーム">
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Form name="normal_login" className="login-form" onFinish={onFinish}>
        <InputItemContainer>
          <Form.Item
            name="memberNo"
            rules={[{ required: true, message: '会員番号は入力必須です' }]}
          >
            <label>
              会員番号
              <Input />
            </label>
          </Form.Item>
        </InputItemContainer>

        <InputItemContainer>
          <Form.Item
            name="userId"
            rules={[{ required: true, message: 'ユーザIDは入力必須です' }]}
          >
            <label>
              ユーザID
              <Input />
            </label>
          </Form.Item>
        </InputItemContainer>

        <InputItemContainer>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'パスワードは入力必須です' }]}
          >
            <label>
              パスワード
              <Input.Password type="password" />
            </label>
          </Form.Item>
        </InputItemContainer>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            送信
          </Button>
          <Button
            type="link"
            href="https://my.pandanet.co.jp/rel/3i.htm"
            target="_blank"
            rel="noreferrer noopener"
          >
            パスワードをお忘れの方はこちらから
          </Button>
        </Form.Item>
      </Form>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 600px;
`;

const InputItemContainer = styled.div`
  margin-bottom: 32px;
`;

const ErrorMessage = styled.div`
  color: red;
`;

export default LoginForm;
