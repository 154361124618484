import React from 'react';
import styled from 'styled-components';
import { makeTextColor, makeImagePath } from '../utils/goban';
import { Point } from '../../types/Point';

type Props = {
  point: Point;
  size: number;
};

export const StoneNode: React.FC<Props> = ({ point, size }: Props) => {
  return point.imgSource ? (
    <Node size={size}>
      <StoneCell size={size} point={point}></StoneCell>
    </Node>
  ) : (
    <></>
  );
};

export const StoneCell = ({ point, size }: Props) => {
  return (
    <StoneImgWithTextContainer>
      <StoneImg src={makeImagePath(point.imgSource)} />
      {point.text && (
        <StoneText size={size} color={makeTextColor(point.imgSource)}>
          {point.text}
        </StoneText>
      )}
    </StoneImgWithTextContainer>
  );
};

type NodeProps = {
  size: number;
};

interface StoneTextProps {
  color: string;
  size: number;
}

const Node = styled.div<NodeProps>`
  display: flex;
  margin: 0;
  height: ${(props) => String(props.size) + 'px'};
  max-height: 50px;
  width: ${(props) => String(props.size) + 'px'};
  max-width: 50px;
`;

const StoneImg = styled.img`
  height: auto;
  width: 100%;
  display: block;
`;

const StoneImgWithTextContainer = styled.div`
  height: auto;
  width: 100%;
  position: relative;
`;

const StoneText = styled.div<StoneTextProps>`
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: ${(props) => 0.8 * props.size}px;
  color: ${(props) => props.color};
`;
