import { createContext, FC, useContext } from 'react';
import { useMedia } from 'react-use';

type Props = {
  children: React.ReactNode;
};

type Context = {
  isSpSite: boolean;
};

const MediaQueryContext = createContext<Context>({
  isSpSite: false,
});

export const useMediaQueryContext = (): Context =>
  useContext(MediaQueryContext);

export const MediaQueryProvider: FC<Props> = ({ children }: Props) => {
  const value = {
    isSpSite: useMedia('(max-width: 519px)'),
  };

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};
