import styled from 'styled-components';

type Props = {
  string: string;
};

const StringBox: React.FC<Props> = ({ string }: Props) => {
  return <StyleDiv>{string}</StyleDiv>;
};

const StyleDiv = styled.div`
  width: 100px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
`;

export default StringBox;
