import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { Question } from '../../types/Question';
import Goban from '../goban/Goban';
import { GobanProps } from '../../types/GobanProps';
import Hint from '../common/Hint';
import StringBoxSP from '../common/StringBoxSP';
import CountDownProgressBar, { ProgressBarProps } from './CountDownProgressBar';
import NextButton, { NextButtonProps } from './NextButton';
import RadioButtons, { RadioButtonsProps } from './RadioButtons';

type Props = {
  question?: Question;
  gobanProps?: GobanProps;
  radioButtonsProps: RadioButtonsProps;
  nextButtonProps: NextButtonProps;
  progressBarProps: ProgressBarProps;
};

const TimeAttackContentSP: React.FC<Props> = ({
  question,
  gobanProps,
  radioButtonsProps,
  nextButtonProps,
  progressBarProps,
}) => {
  return (
    <>
      <StyledCard title={question?.header.title}>
        {question?.header.turn && (
          <StringBoxWrapper>
            <StringBoxSP
              string={question.header.turn === 'B' ? '黒番' : '白番'}
            />
          </StringBoxWrapper>
        )}
        <HintWrapper>
          <Hint hint={question?.problem.hint || ''} />
        </HintWrapper>
        <GobanField>
          {gobanProps ? (
            <Goban {...gobanProps} />
          ) : (
            <div>not found problem</div>
          )}
        </GobanField>
        <CountDownProgressBar {...progressBarProps} />
        <RadioButtonsWrapper>
          <RadioButtons {...radioButtonsProps} />
        </RadioButtonsWrapper>
        <NextButtonWrapper>
          <NextButton {...nextButtonProps} />
        </NextButtonWrapper>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)`
  width: 90vw;
`;

const StringBoxWrapper = styled.div`
  margin-top: 5px;
`;

const HintWrapper = styled.div`
  margin-top: 20px;
  padding: 8px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
`;

const GobanField = styled.div`
  margin-top: 16px;
  height: 30vh;
  width: 100%;
`;

const RadioButtonsWrapper = styled.div`
  margin: 20px 10px;
`;

const NextButtonWrapper = styled.div`
  margin-top: 20px;
`;

export default TimeAttackContentSP;
