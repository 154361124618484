import { Collapse } from 'antd';
import styled from 'styled-components';
import Hint from '../common/Hint';
import { GobanProps } from '../../types/GobanProps';
import { useMemo } from 'react';
import React from 'react';
import Goban from '../goban/Goban';
import { StonePoint } from '../../types/StonePoint';
import { SamePositionStonePair } from '../../types/SamePositionStonePair';
import SamePositionStonePairs from './SamePositionStonePairs';
const { Panel } = Collapse;

type Props = {
  selectedOption: string;
  area: GobanProps['area'];
  explains: {
    option: string;
    comment: string;
    stonePoints: StonePoint[];
    samePositionStonePairs?: SamePositionStonePair[];
  }[];
};

const AccordionsSP: React.FC<Props> = ({ selectedOption, area, explains }) => {
  const panels = useMemo(
    () =>
      [...explains]
        .sort((explain, nextExplain) =>
          explain.option.localeCompare(nextExplain.option),
        )
        .map((explain) => {
          const header = `回答${explain.option}の解説`;
          const panelGobanProps: GobanProps = {
            area,
            stonePoints: explain.stonePoints,
          };
          return (
            <Panel header={header} key={explain.option} collapsible="header">
              <Hint hint={explain.comment} />
              <GobanField>
                {panelGobanProps && <Goban {...panelGobanProps} />}
              </GobanField>
              {explain?.samePositionStonePairs && (
                <SamePositionStonePairsWrapper>
                  <SamePositionStonePairs
                    samePositionStonePairs={explain.samePositionStonePairs}
                  />
                </SamePositionStonePairsWrapper>
              )}
            </Panel>
          );
        }),
    [area, explains],
  );

  return <Collapse defaultActiveKey={selectedOption}>{panels}</Collapse>;
};

const GobanField = styled.div`
  margin: 16px auto 0;
  height: 250px;
  width: 250px;
`;

const SamePositionStonePairsWrapper = styled.div`
  margin-top: 10px;
  width: auto;
  display: flex;
  justify-content: center;
`;

export default AccordionsSP;
