import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import styled from 'styled-components';

type Props = {
  loading: boolean;
  errorMessage: string;
  onFinish: (values: Record<string, any>) => void;
};

const LoginFormSP: React.FC<Props> = ({ loading, errorMessage, onFinish }) => {
  return (
    <StyledCard title="ログインフォーム">
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Form name="normal_login" className="login-form" onFinish={onFinish}>
        <Form.Item
          name="memberNo"
          rules={[{ required: true, message: '会員番号は入力必須です' }]}
        >
          <label>
            会員番号
            <Input />
          </label>
        </Form.Item>

        <Form.Item
          name="userId"
          rules={[{ required: true, message: 'ユーザIDは入力必須です' }]}
        >
          <label>
            ユーザID
            <Input />
          </label>
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'パスワードは入力必須です' }]}
        >
          <label>
            パスワード
            <Input.Password type="password" />
          </label>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            送信
          </Button>
        </Form.Item>
        <Form.Item>
          <MessageLink
            type="link"
            href="https://my.pandanet.co.jp/rel/3i.htm"
            target="_blank"
            rel="noreferrer noopener"
          >
            パスワードをお忘れの方はこちらから
          </MessageLink>
        </Form.Item>
      </Form>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 90vw;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const MessageLink = styled(Button)`
  padding: 0px;
  & span {
    text-align: left;
    white-space: pre-wrap;
  }
`;

export default LoginFormSP;
