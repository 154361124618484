import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';

const useFetchCsrfToken = () => {
  const [csrfToken, setCsrfToken] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API_URL}/csrf`,
          {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
            },
            withCredentials: true,
          },
        );
        setCsrfToken(data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        navigate('/login');
      }
    };

    fetchCsrfToken();
  }, []);

  return csrfToken;
};

export default useFetchCsrfToken;
