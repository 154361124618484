import { atom } from 'recoil';
import { QuestionGroup } from '../types/QuestionGroup';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'questionGroupStateStorage',
  storage: localStorage,
});

const defaultQuestionGroup = {
  key: 0,
  questionGroupId: '',
  name: '',
  difficulty: '',
  timeLimit: 0,
  publishedDate: '',
  questions: [],
  displayQuestionNumber: '',
  bestScore: null,
};

export const questionGroupState = atom<QuestionGroup>({
  key: 'questionGroupState',
  default: defaultQuestionGroup,
  effects_UNSTABLE: [persistAtom],
});

export const removeQuestionGroupState = () => {
  localStorage.removeItem('questionGroupStateStorage');
};
