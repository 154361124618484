import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import QuestionGroupsTable from '../components/questionGroups/QuestionGroupsTable';
import QuestionGroupsTableSP from '../components/questionGroups/QuestionGroupsTableSP';
import {
  questionGroupState,
  removeQuestionGroupState,
} from '../states/questionGroupState';
import { removeExplainTablePaginationState } from '../states/explainTablePaginationState';
import useQuestionGroups from '../hooks/useQuestionGroups';
import { useMediaQueryContext } from '../components/provider/MediaQueryProvider';
import { useSetRecoilState } from 'recoil';

export const QuestionGroups: React.FC = () => {
  removeQuestionGroupState();
  removeExplainTablePaginationState();

  const { isSpSite } = useMediaQueryContext();
  const navigate = useNavigate();
  const setQuestionGroup = useSetRecoilState(questionGroupState);
  const [activeTabKey, setActiveTabKey] = useState<string>('normal');
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };
  const { questionGroupsMap, error, loading } = useQuestionGroups();
  if (error) {
    navigate('/login');
  }

  const props = {
    activeTabKey,
    onTabChange,
    questionGroupsMap,
    loading,
    setQuestionGroup,
  };

  return (
    <Container>
      {isSpSite ? (
        <QuestionGroupsTableSP {...props} />
      ) : (
        <QuestionGroupsTable {...props} />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 3% auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default QuestionGroups;
