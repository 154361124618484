import styled from 'styled-components';

type Props = {
  hint: string;
};

const Hint = (props: Props) => {
  return <StyleDiv>{props.hint}</StyleDiv>;
};

const StyleDiv = styled.div`
  font-size: 18px;
`;

export default Hint;
