// スコアに対応したラベルを返す
export const toScoreLabel = (score: number): string => {
  return (
    {
      1: 'D',
      2: 'C',
      3: 'B',
      4: 'A',
      5: 'S',
    }[score] ?? 'D'
  );
};
