import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'explainTablePaginationStorage',
  storage: sessionStorage,
});

export const explainTablePaginationState = atom<number>({
  key: 'explainTablePaginationState',
  default: 1,
  effects: [persistAtom],
});

export const removeExplainTablePaginationState = () => {
  sessionStorage.removeItem('explainTablePaginationStorage');
};
