import React from 'react';
import { Button } from 'antd';

export type NextButtonProps = {
  isLastQuestion: boolean;
  handleNextButton: () => void;
  handleResultButton: () => Promise<void>;
};

const NextButton: React.FC<NextButtonProps> = ({
  isLastQuestion,
  handleNextButton,
  handleResultButton,
}) => {
  return isLastQuestion ? (
    <Button type="primary" size="large" onClick={handleResultButton}>
      結果へ
    </Button>
  ) : (
    <Button type="primary" size="large" onClick={handleNextButton}>
      次へ
    </Button>
  );
};

export default NextButton;
