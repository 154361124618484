import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { questionExplainState } from '../states/questionExplainsState';
import ExplainContent from '../components/explain/ExplainContent';
import { useMediaQueryContext } from '../components/provider/MediaQueryProvider';
import ExplainContentSP from '../components/explain/ExplainContentSP';
import { StonePoint } from '../types/StonePoint';
import { SamePositionStonePair } from '../types/SamePositionStonePair';

const Explain = () => {
  const { isSpSite } = useMediaQueryContext();
  const questionExplain = useRecoilValue(questionExplainState);

  const explains = questionExplain.explains.map((explain) => {
    // 解説図内で重複する碁石をペアとして抽出する
    const samePositionStonePairs: SamePositionStonePair[] =
      extractSamePositionStonePairs(explain.stonePoints);

    // 重複する碁石のうち、後続手は碁盤に表示しないので解説図から除く
    const laterStones = samePositionStonePairs.flatMap(
      ({ laterStones }) => laterStones,
    );
    const stonePointsRemovedLaterStones = explain.stonePoints.filter(
      (stonePoint) => {
        const found = laterStones.find((laterStone) =>
          isSamePoint(laterStone, stonePoint),
        );
        return !found;
      },
    );

    const mergedStonePoints = mergeAndOverwrite(
      questionExplain.problem.stonePoints,
      stonePointsRemovedLaterStones,
    );
    return {
      ...explain,
      stonePoints: mergedStonePoints,
      samePositionStonePairs,
    };
  });

  const explainContentProps = {
    questionExplain: {
      ...questionExplain,
      explains,
    },
    gobanProps: {
      stonePoints: questionExplain.problem.stonePoints,
      area: questionExplain.question.header.area,
    },
  };

  return (
    <Container>
      {isSpSite ? (
        <ExplainContentSP {...explainContentProps} />
      ) : (
        <ExplainContent {...explainContentProps} />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 3% auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mergeAndOverwrite = (
  problemPoints: StonePoint[],
  explainPoints: StonePoint[],
): StonePoint[] => {
  // 問題図の碁石内に解説図と同じ座標の石があれば解説の方で上書き
  // 問題図には存在するが解説図では除去された碁石を取り除く
  const updatedProblemPoints = problemPoints
    .map((problemPoint) => {
      const overwritePoint = explainPoints.find((explainPoint) =>
        isSameXY(problemPoint.xy, explainPoint.xy),
      );
      return overwritePoint || problemPoint;
    })
    .filter((stonePoint) => stonePoint.type !== 'N');

  // explainPointsから新たに追加するべき点を抽出
  const additionalPoints = explainPoints.filter(
    (explainPoint) =>
      !problemPoints.some((problemPoint) =>
        isSameXY(problemPoint.xy, explainPoint.xy),
      ),
  );

  return [...additionalPoints, ...updatedProblemPoints];
};

// StonePoints内で座標が重複するものを抽出し、初手と後続手に分けて返す
const extractSamePositionStonePairs = (
  stonePoints: StonePoint[],
): SamePositionStonePair[] => {
  // 文字列化したxy配列をkeyにして座標ごとにStonePointsをグループ分けする
  const stonePointsGroupByPosition: Record<string, typeof stonePoints> = {};
  stonePoints.forEach((stonePoint) => {
    const xy = stonePoint.xy.toString();
    if (!stonePointsGroupByPosition[xy]) {
      stonePointsGroupByPosition[xy] = [stonePoint];
      return;
    }
    stonePointsGroupByPosition[xy].push(stonePoint);
  });

  // 座標毎にグループ化された各stonePointsを、SamePositionStonePairsを作る
  const samePositionStonePairs = Object.values(stonePointsGroupByPosition)
    // 座標が重複しないならPairは作らない
    .filter((stonePoints) => stonePoints.length >= 2)
    // 各StonePointsを碁石ナンバーの昇順で並び替える
    .map((stonePoints) => {
      return stonePoints.sort((nextStonePoint, stonePoint) => {
        // textに碁石ナンバー以外が入る場合は先頭にする
        const stoneNumberOrNan = parseFloat(stonePoint?.text ?? '');
        const nextStoneNumberOrNan = parseFloat(nextStonePoint?.text ?? '');
        const stoneNumber = isNaN(stoneNumberOrNan) ? -1 : stoneNumberOrNan;
        const nextStoneNumber = isNaN(nextStoneNumberOrNan)
          ? -1
          : nextStoneNumberOrNan;
        return nextStoneNumber - stoneNumber;
      });
    })
    .map((stonePoints) => {
      return {
        firstStone: stonePoints[0],
        laterStones: stonePoints.slice(1),
      };
    });

  return samePositionStonePairs;
};

function isSamePoint(
  stonePoint1: StonePoint,
  stonePoint2: StonePoint,
): boolean {
  return (
    isSameXY(stonePoint1.xy, stonePoint2.xy) &&
    stonePoint1.type === stonePoint2.type &&
    stonePoint1?.text === stonePoint2?.text
  );
}

function isSameXY(xy1: number[], xy2: number[]): boolean {
  return xy1[0] === xy2[0] && xy1[1] === xy2[1];
}

export default Explain;
