import React, { useCallback, useEffect } from 'react';
import { Progress } from 'antd';

export type ProgressBarProps = {
  isLastQuestion: boolean;
  initialTimeLimit: number;
  timeLimit: number;
  setTimeLimit: (timeLimit: number) => void;
  handleNextButton: () => void;
  handleResultButton: () => Promise<void>;
};

const CountDownProgressBar: React.FC<ProgressBarProps> = ({
  isLastQuestion,
  initialTimeLimit,
  timeLimit,
  setTimeLimit,
  handleNextButton,
  handleResultButton,
}) => {
  useEffect(() => {
    const countDownInterval = setInterval(() => {
      if (timeLimit === 0) {
        // 未回答の場合も回答を送信
        isLastQuestion ? handleResultButton() : handleNextButton();
      }
      if (timeLimit && timeLimit > 0) {
        setTimeLimit(timeLimit - 1);
      }
    }, 1000);
    return () => {
      clearInterval(countDownInterval);
    };
  }, [timeLimit]);

  const timeLimitPercent = useCallback(() => {
    return Math.floor((timeLimit / initialTimeLimit) * 100);
  }, [timeLimit]);

  return <Progress showInfo={false} percent={timeLimitPercent()} />;
};

export default CountDownProgressBar;
