import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { visitedLinkState } from '../../states/visitedLinkState';
import { questionExplainState } from '../../states/questionExplainsState';
import { QuestionExplain } from '../../types/QuestionExplain';

type Props = {
  questionExplain: QuestionExplain;
};

const ExplainLink: React.FC<Props> = ({ questionExplain }) => {
  const { key } = questionExplain;
  const [visited, setVisited] = useRecoilState(visitedLinkState(key));
  const setQuestionExplain = useSetRecoilState(questionExplainState);

  return (
    <Link
      key={key}
      onClick={() => {
        setVisited(true);
        setQuestionExplain(questionExplain);
      }}
      to={`/explain`}
      style={{ color: visited ? 'purple' : '#1677FF' }}
    >
      解説
    </Link>
  );
};

export default ExplainLink;
