import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'loginStateStorage',
  storage: sessionStorage,
});

export const loginState = atom<boolean>({
  key: 'loginState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const removeLoginState = () => {
  sessionStorage.removeItem('loginStateStorage');
};
