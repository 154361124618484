import { Card } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { QuestionExplain } from '../../types/QuestionExplain';
import Hint from '../common/Hint';
import { GobanProps } from '../../types/GobanProps';
import Goban from '../goban/Goban';
import AccordionsSP from './AccordionsSP';
import StringBoxSP from '../common/StringBoxSP';

type Props = {
  questionExplain: QuestionExplain;
  gobanProps: GobanProps;
};

const ExplainContentSP: React.FC<Props> = ({ questionExplain, gobanProps }) => {
  const accordionsProps = {
    selectedOption: questionExplain.selectedOption,
    area: questionExplain.question.header.area,
    explains: questionExplain.explains,
  };
  return (
    <StyledCard
      title={
        <Title>
          <LinkToExplains to="/explains">解説一覧に戻る</LinkToExplains>
          <p>{questionExplain?.question.header.title}</p>
        </Title>
      }
      bodyStyle={{
        padding: '24px 10px',
      }}
    >
      {questionExplain.answerOption && (
        <StringBoxWrapper>
          <StringBoxSP string={`正解 : ${questionExplain.answerOption}`} />
          <StringBoxSP string={`回答 : ${questionExplain.selectedOption}`} />
        </StringBoxWrapper>
      )}
      <HintWrapper>
        <Hint hint={questionExplain?.problem.hint ?? ''} />
      </HintWrapper>
      <GobanField>{gobanProps && <Goban {...gobanProps} />}</GobanField>
      <AccordionsWrapper>
        <AccordionsSP {...accordionsProps} />
      </AccordionsWrapper>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 90vw;
`;

const StringBoxWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  margin-top: 10px;
`;

const HintWrapper = styled.div`
  margin-top: 20px;
  padding: 8px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
`;

const LinkToExplains = styled(Link)`
  font-weight: normal;
  font-size: 18px;
`;

const GobanField = styled.div`
  margin: 16px auto 0;
  height: 300px;
  width: 300px;
`;

const AccordionsWrapper = styled.div`
  margin-top: 30px;
`;

export default ExplainContentSP;
