import React, { useState, useRef, memo, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { GobanProps } from '../../types/GobanProps';
import {
  convertStonePointTypeToImageSource,
  isOutOfArea,
  initializeBoard,
  calculateStoneSize,
} from '../utils/goban';
import { StoneNode } from './StoneNode';
import { Point } from '../../types/Point';
import { BoardArea } from '../../types/BoardArea';
import { GobanLayoutSize } from '../../types/GobanLayoutSize';

const Goban: React.FC<GobanProps> = memo(({ stonePoints, area }) => {
  const boardStone: Point[][] = initializeBoard();

  // 碁盤に碁石のイメージとテキストを詰め込む
  stonePoints.forEach((stonePoint) => {
    const imgSource = convertStonePointTypeToImageSource(stonePoint.type);
    const x = stonePoint.xy[0] - 1;
    const y = stonePoint.xy[1] - 1;
    boardStone[x][y].imgSource = imgSource;
    if (stonePoint.text) {
      boardStone[x][y].text = stonePoint.text;
    }
  });

  // 指定エリア外の碁石のイメージを除去
  boardStone.forEach((column, x) => {
    column.forEach((_, y) => {
      if (isOutOfArea(area, x, y)) {
        boardStone[x][y].imgSource = '';
      }
    });
  });

  // GobanLayoutの参照を作成し、要素のサイズを保持する
  const gobanLayoutRef = useRef<HTMLDivElement>(null);
  const [gobanLayoutSize, setGobanLayoutSize] = useState<GobanLayoutSize>({
    width: 0,
    height: 0,
  });

  // GobanLayout要素のサイズを検知・取得する
  useLayoutEffect(() => {
    const gobanLayoutElement = gobanLayoutRef.current;
    const observer = new ResizeObserver(() => {
      if (gobanLayoutElement) {
        const newLayoutSize = {
          width: gobanLayoutElement.offsetWidth,
          height: gobanLayoutElement.offsetHeight,
        };
        setGobanLayoutSize(newLayoutSize);
      }
    });

    if (gobanLayoutElement) {
      observer.observe(gobanLayoutElement);
    }

    return () => {
      if (gobanLayoutElement) {
        observer.unobserve(gobanLayoutElement);
      }
    };
  }, []);

  // GobanLayoutのサイズと指定エリアを元に碁石のサイズを計算する
  const { right, left, top, bottom } = area;
  const boardArea: BoardArea = {
    xRange: right - left + 1,
    yRange: bottom - top + 1,
  };
  const stoneSize = calculateStoneSize(gobanLayoutSize, boardArea);

  return (
    <GobanLayout ref={gobanLayoutRef}>
      <GobanBackground
        stoneSize={stoneSize}
        verticalStoneNumber={boardArea.yRange}
        horizontalStoneNumber={boardArea.xRange}
      >
        {boardStone.map((column, x) => (
          <NodeCol key={x}>
            {column.map((point, y) => (
              <StoneNode key={y} size={stoneSize} point={point}></StoneNode>
            ))}
          </NodeCol>
        ))}
      </GobanBackground>
    </GobanLayout>
  );
});

type GobanBackgroundProps = {
  stoneSize: number;
  verticalStoneNumber: number;
  horizontalStoneNumber: number;
};

const GobanLayout = styled.div`
  height: 90%;
  width: auto;
  display: flex;
  justify-content: center;
`;

const GobanBackground = styled.div<GobanBackgroundProps>`
  display: flex;
  width: ${(props) =>
    String(props.stoneSize * props.horizontalStoneNumber) + 'px'};
  height: ${(props) =>
    String(props.stoneSize * props.verticalStoneNumber) + 'px'};
  flex-direction: row;
  justify-content: center;
`;

const NodeCol = styled.div`
  flex-direction: column;
`;

export default Goban;
