import { Card, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QuestionGroup, QuestionGroupsMap } from '../../types/QuestionGroup';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  activeTabKey: string;
  onTabChange: (key: string) => void;
  questionGroupsMap?: QuestionGroupsMap;
  loading: boolean;
  setQuestionGroup: (state: QuestionGroup) => void;
};

const QuestionGroupsTable: React.FC<Props> = ({
  activeTabKey,
  onTabChange,
  questionGroupsMap,
  loading,
  setQuestionGroup,
}) => {
  const columns: ColumnsType<QuestionGroup> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '時間成績',
      dataIndex: ['bestScoreLabel', 'time'],
      key: 'timeScoreLabel',
    },
    {
      title: '正解率',
      dataIndex: ['bestScoreLabel', 'accuracy'],
      key: 'accuracyScoreLabel',
    },
    {
      title: '総合成績',
      dataIndex: ['bestScoreLabel', 'total'],
      key: 'totalScoreLabel',
    },
    {
      title: '各問題はこちら',
      dataIndex: 'key',
      key: 'key',
      render: (key, record) => (
        <Link
          onClick={() => {
            setQuestionGroup(record);
          }}
          to={`/time-attack`}
        >
          問題を解く
        </Link>
      ),
    },
  ];

  const exerciseCorses: Record<string, React.ReactNode> = {
    beginner: (
      <Table
        columns={columns}
        dataSource={questionGroupsMap?.beginner}
        loading={loading}
      />
    ),
    easy: (
      <Table
        columns={columns}
        dataSource={questionGroupsMap?.easy}
        loading={loading}
      />
    ),
    normal: (
      <Table
        columns={columns}
        dataSource={questionGroupsMap?.normal}
        loading={loading}
      />
    ),
    hard: (
      <Table
        columns={columns}
        dataSource={questionGroupsMap?.hard}
        loading={loading}
      />
    ),
  };

  interface Level {
    key: string;
    tab: string;
  }

  const level: Level[] = [
    {
      key: 'beginner',
      tab: '入門',
    },
    {
      key: 'easy',
      tab: '初級',
    },
    {
      key: 'normal',
      tab: '中級',
    },
    {
      key: 'hard',
      tab: '上級',
    },
  ];

  return (
    <StyledCard
      tabList={level}
      activeTabKey={activeTabKey}
      onTabChange={(key) => onTabChange(key)}
    >
      {exerciseCorses[activeTabKey]}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 70%;
  min-width: 550px;
`;

export default QuestionGroupsTable;
