import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { QuestionExplain } from '../types/QuestionExplain';

const { persistAtom } = recoilPersist({
  key: 'questionExplainStateStorage',
  storage: localStorage,
});

export const questionExplainState = atom<QuestionExplain>({
  key: 'questionExplainState',
  effects_UNSTABLE: [persistAtom],
});

export const removeQuestionExplainState = () => {
  localStorage.removeItem('questionExplainStateStorage');
};
