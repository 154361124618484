import axios from 'axios';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { loginState } from '../states/loginState';

type LoginAPIResponse = {
  success: boolean;
  memberNo?: string;
  userId?: string;
};

// ログインAPIを叩くメソッド、ローディング判定値、エラーメッセージを返すフック
const useLogin = () => {
  const setIsLogin = useSetRecoilState(loginState);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const login = async (values: Record<string, any>) => {
    try {
      setLoading(true);
      const { data } = await axios.post<LoginAPIResponse>(
        `${process.env.REACT_APP_PUBLIC_API_URL}/login`,
        values,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
          withCredentials: true,
          validateStatus: (status) => {
            // ステータスコードが500番台のときはcatchに入る
            return status < 500;
          },
        },
      );

      if (data.success) {
        setIsLogin(true);
        return true;
      } else {
        setErrorMessage('入力値の組み合わせが不正です');
        return false;
      }
    } catch (err) {
      setErrorMessage('ログインに失敗しました');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, errorMessage };
};

export default useLogin;
