import { Descriptions } from 'antd';
import styled from 'styled-components';
import React from 'react';
import { toScoreLabel } from '../utils/scoreConverter/toScoreLabel';
import { toTotalScoreLabel } from '../utils/scoreConverter/toTotalScoreLabel';
import { Score } from '../../types/UserAnswer';

type Props = {
  bestScore: Score | null;
  currentScore?: Score;
};

const ScoreTableSP: React.FC<Props> = ({ bestScore, currentScore }) => {
  return (
    <StyledDiv>
      <StyledDescriptions size="small" layout="vertical" bordered>
        <StyledDescriptions.Item label="時間成績">
          {toScoreLabel(currentScore?.time ?? 1)}
        </StyledDescriptions.Item>
        <StyledDescriptions.Item label="正答率">
          {toScoreLabel(currentScore?.accuracy ?? 1)}
        </StyledDescriptions.Item>
        <StyledDescriptions.Item label="総合成績">
          {toTotalScoreLabel(currentScore?.total ?? 0)}
        </StyledDescriptions.Item>
      </StyledDescriptions>

      <StyledDescriptions size="small" layout="vertical" bordered>
        <StyledDescriptions.Item label="ベストスコア">
          {toTotalScoreLabel(bestScore?.total ?? currentScore?.total ?? 0)}
        </StyledDescriptions.Item>
        <StyledDescriptions.Item label="今回のスコア">
          {toTotalScoreLabel(currentScore?.total ?? 0)}
        </StyledDescriptions.Item>
      </StyledDescriptions>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
`;

const StyledDescriptions = styled(Descriptions)`
  width: 100%;
  .ant-descriptions-view {
    border-radius: 0;
  }
  .ant-descriptions-item-label {
    font-weight: bold;
    text-align: center;
  }
  .ant-descriptions-item-content {
    text-align: center;
    background-color: #fff;
  }
`;

export default ScoreTableSP;
